var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-validation" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        this.hasOfflineQuery
          ? _c("div", { staticClass: "offline-warning" }, [
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(_vm.$t("validation.info_offline")),
                },
              }),
            ])
          : _vm._e(),
        _c("h1", [
          _vm._v(
            _vm._s(_vm.$t("validation.title", { name: _vm.currentUser.nom }))
          ),
        ]),
        _vm._l(_vm.getSummary, function (dogs, raceId) {
          return _c("div", { key: raceId, staticClass: "results" }, [
            _c("div", { staticClass: "header" }, [
              _c(
                "div",
                { attrs: { title: _vm.$t("validation.header_race") } },
                [_vm._v(_vm._s(_vm.$t("validation.header_race")))]
              ),
              _c(
                "div",
                { attrs: { title: _vm.$t("validation.header_sexe") } },
                [_vm._v(_vm._s(_vm.$t("validation.header_sexe")))]
              ),
              _c(
                "div",
                { attrs: { title: _vm.$t("validation.header_classe") } },
                [_vm._v(_vm._s(_vm.$t("validation.header_classe")))]
              ),
              _c(
                "div",
                { attrs: { title: _vm.$t("validation.header_nr_cat") } },
                [_vm._v(_vm._s(_vm.$t("validation.header_nr_cat")))]
              ),
              _c(
                "div",
                { attrs: { title: _vm.$t("validation.header_classement") } },
                [_vm._v(_vm._s(_vm.$t("validation.header_classement")))]
              ),
              _c(
                "div",
                { attrs: { title: _vm.$t("validation.header_qualifier_id") } },
                [_vm._v(_vm._s(_vm.$t("validation.header_qualifier_id")))]
              ),
              _c(
                "div",
                { attrs: { title: _vm.$t("validation.header_rewards") } },
                [_vm._v(_vm._s(_vm.$t("validation.header_rewards")))]
              ),
              _c(
                "div",
                { attrs: { title: _vm.$t("validation.header_comments") } },
                [_vm._v(_vm._s(_vm.$t("validation.header_comments")))]
              ),
            ]),
            _c(
              "div",
              { staticClass: "body" },
              _vm._l(dogs, function (d) {
                return _c("div", { key: d.nr_cat, staticClass: "dog" }, [
                  _c("div", {
                    staticClass: "race_id",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.getRaceLabel(_vm.getRaceById(raceId))
                      ),
                    },
                  }),
                  _c("div", {
                    staticClass: "sex",
                    domProps: { innerHTML: _vm._s(_vm.getSexLabel(d)) },
                  }),
                  _c("div", {
                    staticClass: "classe_id",
                    domProps: { innerHTML: _vm._s(d.classe_id) },
                  }),
                  _c("div", {
                    staticClass: "nr_cat",
                    domProps: { innerHTML: _vm._s(d.nr_cat) },
                  }),
                  _c("div", {
                    staticClass: "classement",
                    domProps: { innerHTML: _vm._s(_vm.getRanking(d)) },
                  }),
                  _c("div", {
                    staticClass: "qualificatif_id",
                    domProps: { innerHTML: _vm._s(_vm.getQualifierLabel(d)) },
                  }),
                  _c("div", {
                    staticClass: "recompenses",
                    domProps: { innerHTML: _vm._s(_vm.getRewardsLabel(d)) },
                  }),
                  _c("div", {
                    staticClass: "commentaires",
                    domProps: { innerHTML: _vm._s(d.commentaire) },
                  }),
                ])
              }),
              0
            ),
          ])
        }),
        !_vm.has_data_to_validate
          ? _c("div", [_vm._v(_vm._s(_vm.$t("validation.missing_data")))])
          : _vm._e(),
        !this.isValidated &&
        !_vm.signature_has_been_sent &&
        _vm.has_data_to_validate
          ? _c(
              "div",
              [
                _c("hr"),
                _c("h2", {
                  domProps: { innerHTML: _vm._s(_vm.$t("validation.header")) },
                }),
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("validation.confirmation")),
                  },
                }),
                _c("h2", {
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("validation.signature")),
                  },
                }),
                _c("signature", {
                  ref: "signature_juge",
                  attrs: {
                    signature_id: "signature_juge",
                    original_image: _vm.stored_signature,
                    has_existing: _vm.stored_signature !== null,
                  },
                  on: { "save-signature": _vm.onSaveSignature },
                }),
              ],
              1
            )
          : _vm._e(),
        this.isValidated
          ? _c("div", { staticClass: "information" }, [
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(_vm.$t("validation.info_validated")),
                },
              }),
            ])
          : _vm._e(),
      ],
      2
    ),
    _vm.signature_has_been_sent
      ? _c("div", { staticClass: "thanks" }, [
          _c("p", {
            domProps: { innerHTML: _vm._s(_vm.$t("validation.bravo")) },
          }),
        ])
      : _vm._e(),
    _vm.an_error_occured
      ? _c("div", { staticClass: "thanks" }, [
          _c("p", {
            domProps: { innerHTML: _vm._s(_vm.$t("validation.error")) },
          }),
        ])
      : _vm._e(),
    !this.isValidated &&
    _vm.stored_signature !== "" &&
    !_vm.signature_has_been_sent &&
    _vm.has_data_to_validate
      ? _c("div", { staticClass: "actions" }, [
          !_vm.signature_defined
            ? _c("h4", [_vm._v(_vm._s(_vm.$t("signature.signature_required")))])
            : _vm._e(),
          _c(
            "button",
            { staticClass: "info action", on: { click: _vm.onSaveOnDevice } },
            [_vm._v(_vm._s(_vm.$t("validation.save_local")))]
          ),
          _c(
            "button",
            {
              staticClass: "submit action",
              attrs: {
                disabled: !_vm.signature_defined || _vm.hasOfflineQuery,
                title: !_vm.signature_defined
                  ? _vm.$t("signature.signature_required")
                  : "",
              },
              on: { click: _vm.onValidate },
            },
            [_vm._v(_vm._s(_vm.$t("validation.submit")))]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }