var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "signature" },
    [
      _c("vue-drawing-canvas", {
        ref: "signature",
        class: { hidden: !this.canEditImage },
        attrs: {
          canvasId: _vm.signature_id,
          image: _vm.image,
          lock: _vm.locked,
        },
        on: {
          "update:image": function ($event) {
            _vm.image = $event
          },
        },
      }),
      _vm.canEditImage
        ? _c("div", { staticClass: "actions" }, [
            !_vm.locked
              ? _c(
                  "div",
                  {
                    ref: "action-restart",
                    attrs: { title: _vm.$t("signature.unlock") },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.$refs.signature.reset()
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass:
                          "feather feather-refresh-cw hover:border-gray-300 hover:border hover:cursor-pointer zoom",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          fill: "none",
                          width: "24",
                          height: "24",
                          viewBox: "0 0 24 24",
                          stroke: "currentColor",
                          "stroke-width": "2",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                        },
                      },
                      [
                        _c("polyline", {
                          attrs: { points: "23 4 23 10 17 10" },
                        }),
                        _c("polyline", { attrs: { points: "1 20 1 14 7 14" } }),
                        _c("path", {
                          attrs: {
                            d: "M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" " + _vm._s(_vm.$t("signature.erase")) + " "),
                  ]
                )
              : _vm._e(),
            !_vm.locked
              ? _c(
                  "div",
                  {
                    attrs: { title: "Enregistrer" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.onSaveImage.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass:
                          "feather feather-save hover:border-gray-300 hover:border hover:cursor-pointer zoom",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "24",
                          height: "24",
                          viewBox: "0 0 24 24",
                          fill: "none",
                          stroke: "currentColor",
                          "stroke-width": "2",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z",
                          },
                        }),
                        _c("polyline", {
                          attrs: { points: "17 21 17 13 7 13 7 21" },
                        }),
                        _c("polyline", { attrs: { points: "7 3 7 8 15 8" } }),
                      ]
                    ),
                    _vm._v(" " + _vm._s(_vm.$t("signature.save")) + " "),
                  ]
                )
              : _vm._e(),
          ])
        : _vm._e(),
      !_vm.canEditImage
        ? [
            _c("img", { attrs: { src: _vm.original_image, alt: "Signature" } }),
            _c("div", { staticClass: "actions" }, [
              _c(
                "div",
                {
                  ref: "action-reset",
                  attrs: { title: _vm.$t("signature.unlock") },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onUnlockImage.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "feather feather-unlock",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "24",
                        height: "24",
                        viewBox: "0 0 24 24",
                        fill: "none",
                        stroke: "currentColor",
                        "stroke-width": "2",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                      },
                    },
                    [
                      _c("rect", {
                        attrs: {
                          x: "3",
                          y: "11",
                          width: "18",
                          height: "11",
                          rx: "2",
                          ry: "2",
                        },
                      }),
                      _c("path", { attrs: { d: "M7 11V7a5 5 0 0 1 9.9-1" } }),
                    ]
                  ),
                ]
              ),
              _c("div", { staticClass: "message" }, [
                _vm._v(_vm._s(_vm.$t("signature.is_saved"))),
              ]),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }